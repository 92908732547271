import { FETCH_ATTCHS_REQUEST, FETCH_ATTCHS_SUCCESS, FETCH_ATTCHS_FAILURE } from './attchsTypes';

const initialState = {
    loading: false,
    weapons: {},
    error: ''
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_ATTCHS_REQUEST: 
            return {
                ...state,
                loading: true
            }
        case FETCH_ATTCHS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                weapons: {
                    ...state.weapons,
                    [action.payload.weapon]: action.payload.data,
                }
            }
        case FETCH_ATTCHS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}

export default reducer;