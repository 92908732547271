import React from 'react';
import { Active, Default, ActiveIcon, DefaultIcon } from './FilterChip';
import { useSelector, useDispatch } from 'react-redux';
import { setGameStatus, filterWeapons } from '../../redux';

const FilterChipGame = ({name}) => {
    const games = useSelector(state => state.games.data);
    const dispatch = useDispatch(); 

    const handleClick = () => {
        dispatch(setGameStatus(name));
        dispatch(filterWeapons());
    }

    if(games[name]) {
        return <Active icon={<ActiveIcon />} label={name} onClick={() => handleClick()} />
    } else {
        return <Default icon={<DefaultIcon />} label={name} onClick={() => handleClick()} />
    }
}

export default FilterChipGame;
