import { FETCH_GAMES_REQUEST, FETCH_GAMES_FAILURE, FETCH_GAMES_SUCCESS, SET_GAME_STATUS } from './gamesTypes';
import { toggleActive } from '../../helpers/general';

const initialState = {
    loading: false,
    data: {},
    error: ''
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_GAMES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GAMES_SUCCESS:
            const data = {ALL: true}
            action.payload.map(item => data[item.game] = false);
            return {
                ...state,
                loading: false,
                data,
                error: ''
            }
        case FETCH_GAMES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }
        case SET_GAME_STATUS:
            return {
                ...state,
                data: toggleActive(state.data, action.payload),
            }
        default: 
            return state;
    }
}

export default reducer;