import { FETCH_WEAPONS_REQUEST, FETCH_WEAPONS_SUCCESS, SET_SORTING_TYPE, SET_SORTING_VALUE, FETCH_WEAPONS_FAILURE } from './weaponsTypes';
import { setAllModifiers } from '../';
import axios from 'axios';
import api from '../../helpers/api';

export const fetchWeapons = () => async dispatch => {
    dispatch(fetchWeaponsRequest());

    try {
        const res = await axios.get(`${api}weapons/`);
        dispatch(fetchWeaponsSuccess(res.data));
        dispatch(setAllModifiers(res.data));
    } catch(err) {
        dispatch(fetchWeaponsFailure(err));
    }
} 

export const filterWeapons = () => async (dispatch, getState) => {
    const classState = getState().classes.data;
    const gameState = getState().games.data;
    let classes, games, res;

    if(classState.ALL) classes = Object.keys(classState).filter(item => item !== 'ALL');
    if(gameState.ALL) games = Object.keys(gameState).filter(item => item !== 'ALL');

    if(!classState.ALL) classes = Object.entries(classState).filter(item => item[1]).map(item => item[0]);
    if(!gameState.ALL) games = Object.entries(gameState).filter(item => item[1]).map(item => item[0]);

    try { 
        if(classes.length > 0 && games.length > 0) res = await axios.get(`${api}weapons/sort/?classes=${classes.join('*')}&games=${games.join('*')}`);
        if(classes.length > 0 && games.length === 0) res = await axios.get(`${api}weapons/class/?classes=${classes.join('*')}`);
        if(classes.length === 0 && games.length > 0) res = await axios.get(`${api}weapons/game/?games=${games.join('*')}`);

        dispatch(fetchWeaponsSuccess(res.data));
    } catch(err) {
        dispatch(fetchWeaponsFailure(err))
    }
}   

export const fetchWeaponsRequest = () => ({
    type: FETCH_WEAPONS_REQUEST,
});

export const fetchWeaponsSuccess = data => ({
    type: FETCH_WEAPONS_SUCCESS,
    payload: data
});

export const fetchWeaponsFailure = error => ({
    type: FETCH_WEAPONS_FAILURE,
    payload: error
});

export const setSortingType = sortBy => ({
    type: SET_SORTING_TYPE,
    payload: sortBy
});

export const setSortingValue = sortValue => ({
    type: SET_SORTING_VALUE,
    payload: sortValue
})