import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWeapons } from '../../redux';
import Loader from '../Loader';
import SingularWeapon from './SingularWeapon';
import sortObjectsArray from 'sort-objects-array';

const Weapons = () => {
    const { data, loading, sortValue, sortType } = useSelector(state => state.weapons);
    const dispatch = useDispatch();

    // Have to Add Here, As Array Sorting Error Occurs on Apache Server
    const magazineSortFix = data.map(weapon => ({
        ...weapon,
        magazine_size: Number(weapon.magazine_size)
    }));

    useEffect(() => {
        dispatch(fetchWeapons());
    }, []);

    if(loading) {
        return <Loader />
    } else {
        if(sortValue === 'default') return data.map(weapon => <SingularWeapon data={weapon} key={weapon.id} />);
        return sortObjectsArray(magazineSortFix, sortValue, sortType).map(weapon => <SingularWeapon data={weapon} key={weapon.id} />)
    }
}   

export default Weapons;
