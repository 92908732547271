import { createTheme } from "@mui/material";

export default createTheme({
    palette: {
        primary: {
            main: '#26FFDF',
        },
        secondary: {
            main: '#ff5722'
        },
        success: {
            main: '#00E676'
        },
        error: {
            main: '#F44336'
        },
        background: {
            default: '#000',
            paper: '#084b5a'
        },
        text: {
            primary: '#fff'
        }
    },
    typography: {
        subtitle1: {
            fontWeight: 700,
            fontSize: 20
        },
        subtitle2: {
            fontWeight: 700
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                    textTransform: 'capitalize',
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: 700
                }
            }
        }
    }
});