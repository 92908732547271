import React, { useEffect } from 'react';
import FilterChipGame from './FilterChipGame';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGames } from '../../redux';
import Loader from '../Loader';

const FilterByGame = () => {
    const {data, loading} = useSelector(state => state.games);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchGames());
    }, []);

    if(loading) {
        return <Loader />
    } else {
        return (
            <Box sx={{display: 'flex'}}>
                {
                    Object.keys(data).map(item => <FilterChipGame name={item} key={item} />)
                }
            </Box>
        );
    }
}

export default FilterByGame;
