import React from 'react';
import { Box } from '@mui/material';
import Social from './Social';

const Footer = () => {
    return (
        <Box sx={{my: 4, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            <Social name="twitter" url="https://twitter.com" />
            <Social name="facebook" url="https://facebook.com" />
            <Social name="instagram" url="https://instagram.com" />
            <Social name="youtube" url="https://youtube.com" />
            <Social name="twitch" url="https://twitch.com" />
            <Social name="whatsapp" url="https://whatsapp.com" />
        </Box>        
    );
}

export default Footer;
