import { FETCH_CLASSES_REQUEST, FETCH_CLASSES_FAILURE, FETCH_CLASSES_SUCCESS, SET_CLASS_STATUS } from './classesTypes';
import { toggleActive } from '../../helpers/general';

const initialState = {
    loading: false,
    data: {},
    error: ''
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_CLASSES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_CLASSES_SUCCESS:
            const data = {ALL: true};
            action.payload.forEach(item => data[item.class] = false);
            return {
                ...state,
                loading: false,
                data,
                error: ''
            }
        case FETCH_CLASSES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }
        case SET_CLASS_STATUS:
            return {
                ...state,
                data: toggleActive(state.data, action.payload),
            }
        default: 
            return state;
    }
}

export default reducer;