import React, { useEffect, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { formatAttchDetails } from '../../helpers/attchs';
import Modifier from './Modifier';
import { uid } from 'uid';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModifier } from '../../redux';
import { getImgSrc } from '../../helpers/attchs';

const imgStyles = {
    width: 36
}

const AttachmentTooltip = ({data, positives, negatives}) => (
    <>
        <Typography variant="body2" sx={{my: 1}} children={data.name} />
            { (positives) ? positives.map(text => <Modifier key={uid()} text={text} positive />) : '' }
            { (negatives) ? negatives.map(text => <Modifier key={uid()} text={text} />) : '' }
    </>
);

const SingleAttachment = ({data, id, weaponName}) => {
    const positiveDetails = (data.details_positive) ? formatAttchDetails(data.details_positive) : false;
    const negativeDetails = (data.details_negative) ? formatAttchDetails(data.details_negative) : false;
    const dispatch = useDispatch();
    const activeAttch = useSelector(state => state.modifiers[`ID_${id}`].activeAttchs[data.type.toLowerCase()].id);
        
    const [imgSrc, setImgSrc] = useState(false);
    useEffect(() => {
        if(!imgSrc) {
            getImgSrc(data.name.replace('/', ' '), weaponName, data.type, data.id, setImgSrc);
        }
    }, []); 

    const setActive = () => {
        dispatch(toggleModifier(data, id));
    }

    if(!imgSrc) {
        return <div></div>
    } else if(activeAttch === data.id) {
        return (
            <Tooltip arrow title={<AttachmentTooltip data={data} positives={positiveDetails} negatives={negativeDetails} />}>
                <Box onClick={() => setActive()} sx={{cursor: 'pointer', borderTop: 2, borderBottom: 2, borderColor: 'secondary.main', display: 'flex', alignItems: 'center'}}>
                    <img style={imgStyles} src={imgSrc} alt="Weapon Attachment Scope" />
                </Box>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip arrow title={<AttachmentTooltip data={data} positives={positiveDetails} negatives={negativeDetails} />}>
                <Box onClick={() => setActive()} sx={{cursor: 'pointer'}}>
                    <img style={imgStyles} src={imgSrc} alt="Weapon Attachment Scope" />
                </Box>
            </Tooltip>
        );
    }
}

export default SingleAttachment;
