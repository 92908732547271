import { FETCH_CLASSES_REQUEST, FETCH_CLASSES_FAILURE, FETCH_CLASSES_SUCCESS, SET_CLASS_STATUS } from './classesTypes';
import axios from 'axios';
import api from '../../helpers/api';

export const fetchClasses = () => async dispatch => {
    dispatch(fetchClassesRequest());

    try  {
        const res = await axios.get(`${api}weapons/classes/`);
        dispatch(fetchClassesSuccess(res.data))
    } catch(err) {
        dispatch(fetchClassesFailure(err))
    }
}

export const setClassStatus = value => ({
    type: SET_CLASS_STATUS,
    payload: value,
})

export const fetchClassesRequest = () => ({
    type: FETCH_CLASSES_REQUEST
});

export const fetchClassesSuccess = data => ({
    type: FETCH_CLASSES_SUCCESS,
    payload: data,
});

export const fetchClassesFailure = error => ({
    type: FETCH_CLASSES_FAILURE,
    payload: error
});