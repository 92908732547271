import React from 'react';
import { Typography } from '@mui/material';
import SingleAttachment from './SingleAttachment';
import Scroller from './Scroller';
import { determineOnDisplay } from '../../helpers/attchs';

const AttachmentRow = ({data, type, weapon}) => {

    return (
        <>
            <Typography variant="h6" children={type} gutterBottom />
            <Scroller itemsLength={data.length}>
                {
                    data.map(attch => (
                        <SingleAttachment key={attch.id} data={attch} id={weapon.id} weaponName={weapon.name} />
                    ))
                }
            </Scroller>
        </>
    );
}

export default AttachmentRow;
