import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const Loader = () => {
    return (
        <Box sx={{color: 'common.white', display: 'flex', justifyContent: 'center', my: 1}}>
            <CircularProgress color="inherit" />
        </Box>
    );
}

export default Loader;
