import React, { useRef, useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import { useSelector, useDispatch } from 'react-redux';
import { setChartData } from '../../helpers/chart';

Chart.register(...registerables);

const LineGraph = () => {
    const dispatch = useDispatch();
    const { active, data } = useSelector(state => state.chart); 
    const activeData = active.map(id => data[id]);
    const chartElement = useRef(null);
    const [chartInstance, setChartInstance] = useState(false);

    useEffect(() => {
        if(chartElement && active.length > 0) {
            const ctx = chartElement.current.getContext('2d');
            const chartData = setChartData(activeData);
            if(chartInstance) chartInstance.destroy();
            setChartInstance(new Chart(ctx, chartData));
        }   
    }, [active]);
    // Originally data 

    return <canvas ref={chartElement} height="100%" width="100%" style={{maxHeight: 500}}></canvas>
}

export default LineGraph;
