import React from 'react';
import AdvantageIcon from '../icons/AdvantageIcon';
import DisadvantageIcon from '../icons/DisadvantageIcon';
import { Stack, Typography } from '@mui/material';

const Modifier = ({text, positive}) => {

    if(positive) {
        return (
            <Stack spacing={1} direction="row">
                <AdvantageIcon />
                <Typography children={text.trim()} variant="body2" />
            </Stack>
        );
    } else {    
        return (
            <Stack spacing={1} direction="row">
                <DisadvantageIcon />
                <Typography children={text.trim()} variant="body2" />
            </Stack>
        );
    }
}

export default Modifier;
