import { SET_MODIFIER, SET_ALL_MODIFIERS, UPDATE_ACTIVE_MODIFIERS } from './modifiersTypes';

export const toggleModifier = (attch, weaponId) => dispatch => {
    dispatch(setModifier(attch, weaponId));
    dispatch(updateActiveModifiers(weaponId));
} 

export const updateActiveModifiers = weaponId => ({
    type: UPDATE_ACTIVE_MODIFIERS,
    payload: `ID_${weaponId}`
});

export const setModifier = (attch, weaponId) => ({
    type: SET_MODIFIER,
    payload: {
        attch,
        weaponId
    }
});

export const setAllModifiers = allWeapons => ({
    type: SET_ALL_MODIFIERS,
    payload: allWeapons
});