import { Chip, styled } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

export const Active = styled(Chip)({
    background: 'rgba(38, 255, 223, 0.3)',
    borderRadius: 5,
    textTransform: 'uppercase',
    width: 140,
    margin: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
        backgroundColor: '#696969'
    }
});

export const Default = styled(Chip)({
    background: '#212121',
    borderRadius: 5,
    textTransform: 'uppercase',
    width: 140,
    margin: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
        backgroundColor: '#696969'
    }
});

export const ActiveIcon = styled(CircleIcon)(({theme}) => ({
    height: 6,
    color: `${theme.palette.secondary.main} !important`
}));

export const DefaultIcon = styled(CircleIcon)(({theme}) => ({
    height: 6,
    color: '#42424'
}));
