import React from 'react';
import { Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { calcCombinedModifiers } from '../../helpers/attchs';

const Spacer = () => <Box sx={{width: 24, ml: 1}}></Box>

const calcStyles = {
    minWidth: 24,
    ml: 1,
}

const PercentageBar = ({baseValue, modifiedValue}) => {
    const styles = {
        bgcolor: 'background.default',
        display: 'flex',
        width: {
            xs: 125,
            md: 100,
            xl: 150
        },
        p: '4px',
        border: 1,
        borderColor: 'primary.main',
        borderRadius: '2.5px',
        height: 14
    }

    return (
        <Box sx={styles}>
            <Box sx={{flexBasis: `${(modifiedValue && modifiedValue < 1) ? baseValue - Math.abs(modifiedValue) : baseValue}%`, bgcolor: 'primary.main', height: 4}} />
            {(modifiedValue && modifiedValue !== baseValue) ? <Box sx={{bgcolor: (modifiedValue > 1) ? 'success.main' : 'error.main', flexBasis: `${Math.abs(modifiedValue)}%`, height: 4}} /> : '' }
        </Box>
    );
};


const Statistic = ({name, baseValue, percentage, weapon}) => {
    const statName = name.toLowerCase().replace(/[\s]/g, '_');
    const lastSelectedType = useSelector(state => state.modifiers[`ID_${weapon}`]?.lastSelectedAttch);   
    const modifiedValue = useSelector(state => state.modifiers[`ID_${weapon}`]?.activeAttchs[lastSelectedType]?.[statName]);
    const activeAttchs = useSelector(state => state.modifiers[`ID_${weapon}`]?.activeAttchs);

    if(percentage) {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}} >
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexGrow: 1}}>
                    <Typography children={name} />
                    <PercentageBar baseValue={baseValue} modifiedValue={modifiedValue} />
                </Box>
                <Box sx={{display: 'flex'}}>
                    <Typography align="center" sx={calcStyles} variant="body2" children={(modifiedValue) ? calcCombinedModifiers(activeAttchs, statName, baseValue) - modifiedValue : (activeAttchs) ? calcCombinedModifiers(activeAttchs, statName, baseValue) : baseValue} />
                    {(modifiedValue && modifiedValue != 0) ? <Typography align="center" sx={{...calcStyles, color: (modifiedValue > 0) ? 'success.main' : 'error.main'}} variant="body2" children={(modifiedValue > 0) ? `+${modifiedValue}` : modifiedValue} /> : <Spacer />}
                    {(modifiedValue && modifiedValue != 0) ? <Typography align="center" sx={{...calcStyles, color: 'primary.main'}} variant="body2" children={calcCombinedModifiers(activeAttchs, statName, baseValue)} /> : <Spacer />} 
                </Box>
            </Box>
        );
    } else {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}} >
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexGrow: 1}}>
                    <Typography children={name} />
                    <Typography sx={{mr: '68px'}} variant="body2" children={(modifiedValue && modifiedValue !== baseValue) ? modifiedValue : baseValue} />
                </Box>
            </Box>
        );
    }
}

export default Statistic;