import React from 'react';
import { Box } from '@mui/material';

const Social = ({url, name}) => {
    return (
        <Box component="a" href={url} target="_blank" rel="noopener noreferrer" sx={{m: 2}}>
            <img src={`assets/icons/socials/${name}-logo.png`} alt={`${name} Icon`} style={{maxHeight: 36}} />
        </Box>
    );
}

export default Social;
