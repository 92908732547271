import React, { useState } from 'react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const arrowStyles = {
    color: 'common.white',
    opacity: '0.6'
}

const Scroller = ({children, itemsLength}) => { 
    let onDisplay = 8;
    const isNeeded = (itemsLength <= onDisplay) ? false : true 
    const [scrollValue, setScrollValue] = useState(0);
    const maxValue = (!isNeeded) ? 0 : -Math.abs((itemsLength - onDisplay) * 36);

    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <IconButton sx={{display: (isNeeded) ? 'block' : 'none'}} onClick={() => setScrollValue(prev => (prev + 36))} disabled={(scrollValue === 0) ? true : false}>
                <ArrowBackIosNewIcon sx={{...arrowStyles}} />
            </IconButton>
            <Box sx={{width: Number(onDisplay * 36), overflow: 'hidden', ml: (!isNeeded) ? '40px' : 0}}>
                <Box sx={{display: 'flex', position: 'relative', left: scrollValue, transition: 'all 0.1s ease-in'}}>
                    {children}
                </Box>
            </Box>
            <IconButton sx={{display: (isNeeded) ? 'block' : 'none'}} onClick={() => setScrollValue(prev => (prev - 36))} disabled={(scrollValue === maxValue) ? true : false}>
                <ArrowForwardIosIcon sx={{...arrowStyles}} />
            </IconButton>
        </Box>
    );
}

export default Scroller;
