import { OPEN_CHART, REFRESH_CHART, SET_CHART_STATUS, FETCH_CHART_REQUEST, FETCH_CHART_SUCCESS, FETCH_CHART_FAILURE } from './chartTypes';
import axios from 'axios';
import api from '../../helpers/api';

export const toggleChart = (weapon, status) => async (dispatch, getState) => {
    const { id } = weapon;
    const { active, data } = getState().chart;
    
    if(status) {
        try {
            if(!data[id]) {
                const res = await axios.get(`${api}chart/weapon/?weapons=${id}`);
                dispatch(fetchChartSuccess(weapon, res.data[0]));
                dispatch(setChartStatus(id, status));
            } else {
                dispatch(setChartStatus(id, status));
            }  
        } catch(err) {
            dispatch(fetchChartFailure(err));   
        }
    } else {
        dispatch(setChartStatus(id, status))
    }

    // [...active, id].forEach(async item => {
    //     if(data[item]) return;
    //     dispatch(fetchChartRequest());

    //     try {
    //         const res = await axios.get(`${api}chart/weapon/?weapons=${id}`);
    //         dispatch(fetchChartSuccess(weapon, res.data[0]));
    //     } catch(err) {
    //         dispatch(fetchChartFailure(err));
    //     }
    // });

    // setTimeout(() => dispatch(setChartStatus(id, status)), 3000)
}

export const fetchChartRequest = () => ({
    type: FETCH_CHART_REQUEST,
});

export const fetchChartSuccess = (weapon, data) => ({
    type: FETCH_CHART_SUCCESS,
    payload: {
        data,
        weapon
    }
});

export const fetchChartFailure = err => ({
    type: FETCH_CHART_FAILURE,
    payload: err
});

export const setChartStatus = (id, status) => ({
    type: SET_CHART_STATUS,
    payload: {
        id, status
    }
});

export const openChart = () => ({
    type: OPEN_CHART,
});

export const refreshChart = () => ({
    type: REFRESH_CHART,
});