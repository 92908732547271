export const toggleActive = (currentState, payload) => {
    const newState = {};

    if(!currentState.ALL && payload === 'ALL') {
        Object.keys(currentState).forEach(item => newState[item] = false);
        newState.ALL = true;
        return newState;
    } else if(currentState.ALL && payload !== 'ALL') {
        return {
            ...currentState,
            ALL: false,
            [payload]: !currentState[payload]
        }
    } else {
        return {
            ...currentState,
            [payload]: !currentState[payload]
        }
    }
}

export const formatName = name => name.toUpperCase().replace(/[\s-]/g, '_');

