import React from 'react';
import {Grid, Typography } from '@mui/material';
import FilterByGame from './FilterByGame';
import FilterByClass from './FilterByClass';
import SortMenu from './SortMenu';

const Filter = () => {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
                <Typography gutterBottom variant="h5" children="Select Game" />
                <FilterByGame />
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography gutterBottom variant="h5" children="Select Class" />
                <FilterByClass />
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography gutterBottom variant="h5" children="Sort By" />
                <SortMenu />
            </Grid>
        </Grid>
    );
}

export default Filter;
