import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAttchs } from '../../redux';
import { sortAttchsByType } from '../../helpers/attchs';
import { formatName } from '../../helpers/general';
import Loader from '../Loader';
import AttachmentRow from './AttachmentRow';

const Attachments = ({weapon}) => {
    const dispatch = useDispatch();
    const attchs = useSelector(state => state.attchs.weapons[formatName(weapon.name)]); 

    useEffect(() => {
        dispatch(fetchAttchs(weapon.name));
    }, []);

    if(typeof attchs === 'undefined') {
        return <Loader />
    } else {
        const sortedAttchs = sortAttchsByType(attchs);
        return (
            <>
                <AttachmentRow type="Ammo" weapon={weapon} data={sortedAttchs.ammo} />
                <AttachmentRow type="Sights" weapon={weapon} data={sortedAttchs.sights} />
                <AttachmentRow type="Barrel" weapon={weapon} data={sortedAttchs.barrel} />
                <AttachmentRow type="Underbarrel" weapon={weapon} data={sortedAttchs.underbarrel} />            
            </>
        )
    }
}

export default Attachments;
