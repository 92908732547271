import { OPEN_CHART, REFRESH_CHART, SET_CHART_STATUS, FETCH_CHART_REQUEST, FETCH_CHART_SUCCESS, FETCH_CHART_FAILURE } from './chartTypes';

const initialState = {
    open: false,
    loading: false,
    active: [],
    data: {},
    chartInstance: null,
    chartContext: null,
    error: '',
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case OPEN_CHART: 
            return {
                ...state,
                open: !state.open,
            }
        case SET_CHART_STATUS:
            const { id, status } = action.payload;
            return (status) 
                ? {
                    ...state,
                    active: (state.active.includes(id)) ? state.active : [...state.active, id],
                } : {
                    ...state,
                    active: state.active.filter(prevId => prevId !== id),
                }
        case FETCH_CHART_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_CHART_SUCCESS:
            const { data, weapon } = action.payload;    
            return {
                ...state,
                loading: false,
                error: '',
                data: {
                    ...state.data,
                    [weapon.id]: {
                        name: weapon.name,
                        damage: [data.dmg_1, data.dmg_2, data.dmg_3, data.dmg_4, data.dmg_5, data.dmg_6],
                        distance: [data.dpf_1, data.dpf_2, data.dpf_3, data.dpf_4, data.dpf_5, data.dpf_6],
                    }
                }
            }
        case FETCH_CHART_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case REFRESH_CHART: 
            const activeData = state.active.map(id => state.data[id]);
            return {
                ...state,
                loading: false,
                chartData: {
                    ...state.chartData,
                    datasets: activeData.map(item => ({
                        label: item.name,
                        borderColor: '#fff',
                        backgroundColor: '#fff',
                        data: item.damage
                    }))
                }
            }
        default:
            return state;
    }
}

export default reducer;