import React from 'react';
import { Fab, Tooltip } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useSelector, useDispatch } from 'react-redux';
import { openChart } from '../../redux';
import CloseIcon from '@mui/icons-material/Close';

const fabStyles = {
    position: 'fixed',
    bottom: 16,
    left: 16
}

const ChartTrigger = () => {
    const dispatch = useDispatch();
    const { open } = useSelector(state => state.chart);

    if(open) {
        return (
            <Fab sx={fabStyles} color="secondary" onClick={() => dispatch(openChart())}> 
                <Tooltip placement="right" title="Close Comparison Graph">
                    <CloseIcon />
                </Tooltip>
            </Fab>
        );
    } else {
        return (
            <Fab sx={fabStyles} color="secondary" onClick={() => dispatch(openChart())}> 
                <Tooltip placement="right" title="Comparison Graph">
                    <QueryStatsIcon />
                </Tooltip>
            </Fab>
        );
    }
    
}

export default ChartTrigger;
