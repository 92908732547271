import React, { useState } from 'react';
import { Typography, Grid, Divider, Button, Box, Stack } from '@mui/material';
import Statistic from './Statistic';
import Attachments from '../attachments/Attachments';
import { useSelector, useDispatch } from 'react-redux';  
import Modifier from '../attachments/Modifier';
import { uid } from 'uid';
import { IKImage } from 'imagekitio-react';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { toggleChart, openChart } from '../../redux';

const Comparing = ({dispatch, weapon}) => (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
        <Box sx={{display: 'flex', alignItems: 'center', my: 2}}>
            <CheckIcon sx={{bgcolor: 'success.main', borderRadius: 100, p: 0.5}} />
            <Typography sx={{ml: 1}} children="Comparing" />
        </Box>
        <Button onClick={() => dispatch(toggleChart(weapon, false))} startIcon={<CloseIcon sx={{bgcolor: 'common.white', p: 0.5, color: 'error.main', borderRadius: 100}} />} color="secondary" variant="contained">Stop</Button>
        <Button onClick={() => dispatch(openChart())} sx={{my: 2}} startIcon={<QueryStatsIcon />} variant="contained" color="secondary">Chart</Button>
    </Stack>
)

const DisabledCompare = () => (
    <Box>
        <Button disabled={true} sx={{my: 2}} fullWidth startIcon={<QueryStatsIcon />} variant="contained" color="secondary">COMPARE</Button>
        <Typography sx={{bgcolor: 'background.default', p: 1, borderRadius: 1}} variant="body2" align="center">Max Comparison Limit of 5.</Typography>
    </Box>
)

const backgroundImageStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '20px',
    my: 3,  
    height: 200,
}

const SingularWeapon = ({data}) => {
    const positiveModifiers = useSelector(state => state.modifiers[`ID_${data.id}`]?.activePositiveModifiers);
    const negativeModifiers = useSelector(state => state.modifiers[`ID_${data.id}`]?.activeNegativeModifiers);
    const dispatch = useDispatch();
    const activeCompare = useSelector(state => state.chart.active.includes(data.id));
    const activeLimit = useSelector(state => (state.chart.active.length >= 5) ? true : false);
    
    return (
        <Grid container sx={{bgcolor: 'background.paper', my: 4, borderRadius: 1, pr: 2, pb: 2}}>
            <Grid item xs={12} md={4} sx={{p: 3}}>
                <Typography sx={{mb: 1}} variant="h6" children={data.name} />
                <Typography sx={{mb: 1, fontStyle: 'italic'}} variant="body1" children={data.class} />
                <Box sx={{...backgroundImageStyles, backgroundImage: `url(/assets/images/weapon-background.png)`}}>
                    <IKImage path={`${data.name.replace(' ', '_')}.png`} style={{maxWidth: 300}} />
                </Box>
                {
                    (activeCompare)
                        ? <Comparing dispatch={dispatch} weapon={{name: data.name, id: data.id}} />
                        : (activeLimit) 
                            ? <DisabledCompare />
                            : <Button onClick={() => dispatch(toggleChart({name: data.name, id: data.id}, true))} sx={{my: 2}} fullWidth startIcon={<QueryStatsIcon />} variant="contained" color="secondary">COMPARE</Button>
                }
            </Grid>
            <Grid item xs={12} md={4} sx={{p: 3}}>
                <Statistic weapon={data.id} percentage name="Firepower" baseValue={data.firepower} />
                <Statistic weapon={data.id} percentage name="Accuracy" baseValue={data.accuracy} />
                <Statistic weapon={data.id} percentage name="Range" baseValue={data.range} />
                <Statistic weapon={data.id} percentage name="Handling" baseValue={data.handling} />
                <Statistic weapon={data.id} name="Rate of Fire" baseValue={data.rate_of_fire} />
                <Statistic weapon={data.id} name="Magazine Size" baseValue={data.magazine_size} />
                <Statistic weapon={data.id} name="Fire Modes" baseValue={data.fire_modes} />
                <Statistic weapon={data.id} name="Zoom Level" baseValue={data.zoom_level} />
                <Divider sx={{borderColor: 'common.white', my: 1, width: '100%'}} />
                <Grid container>
                    <Grid item xs={12} md={6}>
                        {(positiveModifiers) ? positiveModifiers.map(text => <Modifier key={uid()} text={text} positive />) : ''}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {(negativeModifiers) ? negativeModifiers.map(text => <Modifier key={uid()} text={text} />) : ''}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} sx={{p: 3}}>
                <Attachments weapon={data} />
            </Grid>
        </Grid>            
    );
}

export default SingularWeapon;
