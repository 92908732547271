import { FETCH_GAMES_REQUEST, FETCH_GAMES_FAILURE, FETCH_GAMES_SUCCESS, SET_GAME_STATUS } from './gamesTypes';
import axios from 'axios';
import api from '../../helpers/api';

export const fetchGames = () => async dispatch => {
    dispatch(fetchGamesRequest());

    try {
        const res = await axios.get(`${api}weapons/games/`);
        dispatch(fetchGamesSuccess(res.data))
    } catch(err) {
        dispatch(fetchGamesFailure(err))
    }
}

export const setGameStatus = value => ({
    type: SET_GAME_STATUS,
    payload: value
})

export const fetchGamesRequest = () => ({
    type: FETCH_GAMES_REQUEST
});

export const fetchGamesSuccess = data => ({
    type: FETCH_GAMES_SUCCESS,
    payload: data,
});

export const fetchGamesFailure = error => ({
    type: FETCH_GAMES_FAILURE,
    payload: error
});