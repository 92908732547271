import React from 'react';
import { SvgIcon } from '@mui/material';

const DisadvantageIcon = () => {
    return (
        <SvgIcon sx={{position: 'relative', top: 2}}>
            <path d="M0.122287 8.00899C0.203873 7.9252 0.315964 7.87817 0.432912 7.87862H2.71153C2.88928 7.87862 3.03338 7.73451 3.03338 7.55677V0.431254C3.03338 0.193073 3.22645 0 3.46464 0H7.89959C8.13777 0 8.33084 0.193073 8.33084 0.431254V7.55677C8.33084 7.73451 8.47495 7.87862 8.65269 7.87862H10.9377C11.1759 7.87412 11.3726 8.06351 11.3771 8.30164C11.3793 8.42085 11.3321 8.53568 11.2467 8.61884L5.99429 13.8713C5.9144 13.9524 5.80565 13.9987 5.69175 14C5.57562 14.0004 5.46425 13.954 5.38279 13.8713L0.130373 8.61884C-0.0402517 8.45268 -0.0438728 8.17965 0.122287 8.00899Z" fill="#F44336"/>
        </SvgIcon>
    );
}

export default DisadvantageIcon;
