export const updateModifiers = (weapon) => {

    const allPositives = [
        ...weapon.activeAttchs.ammo.positives, 
        ...weapon.activeAttchs.sights.positives, 
        ...weapon.activeAttchs.underbarrel.positives, 
        ...weapon.activeAttchs.barrel.positives
    ];

    const allNegatives = [
        ...weapon.activeAttchs.ammo.negatives, 
        ...weapon.activeAttchs.sights.negatives, 
        ...weapon.activeAttchs.underbarrel.negatives, 
        ...weapon.activeAttchs.barrel.negatives
    ];

    return {
        activePositives: allPositives,
        activeNegatives: allNegatives,
    }
}