import React, { useEffect } from 'react';
import FilterChipClass from './FilterChipClass';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { fetchClasses } from '../../redux';
import Loader from '../Loader';

const FilterByClass = () => {
    const dispatch = useDispatch();
    const {loading, data} = useSelector(state => state.classes);

    useEffect(() => {
        dispatch(fetchClasses());
    }, []);

    if(loading) {
        return <Loader />
    } else {
        return (
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                {
                    Object.keys(data).map(item => <FilterChipClass name={item} key={item} />)
                }
            </Box>
        );
    }
}

export default FilterByClass;
