import React from 'react';
import { TextField, MenuItem, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setSortingType, setSortingValue } from '../../redux';

const menuStyles = {
    minWidth: '100%',
    borderColor: '#fff',
    border: 0.5,
    borderRadius: 1,
    mb: 2
}

const SortMenu = () => {
    const { sortValue, sortType } = useSelector(state => state.weapons);
    const dispatch = useDispatch();

    return (
        <>
            <Typography children="Value to sort by:" />
            <TextField select color="secondary" sx={menuStyles} value={sortValue} onChange={e => dispatch(setSortingValue(e.target.value))}>
                <MenuItem value="default">No Sorting (Default)</MenuItem>
                <MenuItem value="firepower">Firepower</MenuItem>
                <MenuItem value="accuracy">Accuracy</MenuItem>
                <MenuItem value="range">Range</MenuItem>
                <MenuItem value="handling">Handling</MenuItem>
                <MenuItem value="rate_of_fire">Rate of Fire</MenuItem>
                <MenuItem value="magazine_size">Magazine Size</MenuItem>
            </TextField>
            <Typography children="Sorting type:" />
            <TextField select color="secondary" sx={menuStyles} value={sortType} onChange={e => dispatch(setSortingType(e.target.value))}>
                <MenuItem value="asce">Ascending Order</MenuItem>
                <MenuItem value="desc">Descending Order</MenuItem>
            </TextField>
        </>
    );
}

export default SortMenu;
