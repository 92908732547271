import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';
import { Provider } from 'react-redux';
import store from './redux/store';
import { IKContext } from 'imagekitio-react';

render(
    <React.StrictMode>
        <IKContext urlEndpoint="https://ik.imagekit.io/jackkingham">
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </Provider>
        </IKContext>
    </React.StrictMode>,
    document.getElementById('root')
);