import uab from 'unique-array-objects';

const chartColors = [
    '#35CE8D',
    '#B3001B',
    '#D1345B',
    '#3454D1',
    '#ffff00',
];

const getMaxDistance = data => {
    const allDistances = [];
    data.forEach(arr => allDistances.push(...arr.distance));
    return Math.max(...allDistances);
}

let chartOptions = {
    type: 'scatter',
    options: {
        responsive: true,  
        plugins: {
            legend: {
                labels: {
                    boxHeight: 1
                }
            }
        },
        scales: {
            x: {
                max: 0,
                beginAtZero: true
            }
        },
    }
}

const getData = (damageValues, distanceValues, maxDistance) => {
    let highestDamage, lowestDamage;

    const data = damageValues.map((value, index) => {
        if(index === 0) highestDamage = value;
        if(index === damageValues.length - 1) lowestDamage = value;
        return {
            y: value,
            x: distanceValues[index] || maxDistance
        }
    });

    data.unshift({x: 0, y: highestDamage});
    data.push({x: maxDistance + 5, y: lowestDamage})

    return uab(data);
}

export const setChartData = data => {
    const maxDistance = getMaxDistance(data)

    chartOptions.data = {
        datasets: data.map((item, index) => {
            return {
                label: item.name,
                backgroundColor: chartColors[index],
                borderColor: chartColors[index],
                showLine: true,
                borderWidth: 3,
                data: getData(item.damage, item.distance, maxDistance),
            }
        })
    }

    chartOptions.options.scales.x.max = maxDistance + 5;

    return chartOptions;
}