import { SET_ALL_MODIFIERS, SET_MODIFIER, UPDATE_ACTIVE_MODIFIERS } from './modifiersTypes';
import { formatAttchDetails } from '../../helpers/attchs';
import { updateModifiers } from '../../helpers/redux';


const initialState = {}
const defaultWeaponStats = {
    firepower: 0,
    accuracy: 0,
    range: 0,
    handling: 0,
    rate_of_fire: 0,
    magazine_size: 0,
    fire_modes: '',
    zoom_level: 0
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ALL_MODIFIERS:
            const data = {};
            action.payload.forEach(weapon => {
                data[`ID_${weapon.id}`] = {
                    ...weapon,    
                    activePositiveModifiers: [],
                    activeNegativeModifiers: [],
                    activeAttchs: {
                        ammo: {id: '', positives: [], negatives: [], ...defaultWeaponStats},
                        sights: {id: '', positives: [], negatives: [], ...defaultWeaponStats},
                        underbarrel: {id: '', positives: [], negatives: [], ...defaultWeaponStats},
                        barrel: {id: '', positives: [], negatives: [], ...defaultWeaponStats},
                    },
                    lastSelectedAttch: '',
                }
            });
            return data;
        case SET_MODIFIER:
            const weaponId = `ID_${action.payload.weaponId}`;
            const attch = action.payload.attch;
            const type = attch.type.toLowerCase();
            const currentActive = state[weaponId].activeAttchs[type].id;
            const attchId = attch.id;
            if(currentActive === '' || currentActive !== attchId) {
                return {
                    ...state,
                    [weaponId]: {
                        ...state[weaponId],
                        activeAttchs: {
                            ...state[weaponId].activeAttchs,
                            [type]: {
                                id: attchId,
                                positives: (attch.details_positive) ? formatAttchDetails(attch.details_positive) : [],
                                negatives: (attch.details_negative) ? formatAttchDetails(attch.details_negative) : [],
                                firepower: attch.firepower,
                                accuracy: attch.accuracy,
                                range: attch.range,
                                handling: attch.handling,
                                rate_of_fire: attch.rate_of_fire,
                                magazine_size: attch.magazine_size,
                                fire_modes: attch.fire_modes,
                                zoom_level: attch.zoom_level,
                            }
                        },
                        lastSelectedAttch: type,
                    }
                }
            } else {
                return {
                    ...state,
                    [weaponId]: {
                        ...state[weaponId],
                        activeAttchs: {
                            ...state[weaponId].activeAttchs,
                            [type]: {
                                id: '',
                                positives: [],
                                negatives: [],
                                ...defaultWeaponStats,
                            }   
                        },
                        lastSelectedAttch: '',
                    }
                }
            }
        case UPDATE_ACTIVE_MODIFIERS: 
            const { activePositives, activeNegatives } = updateModifiers(state[action.payload]);
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    activePositiveModifiers: activePositives,
                    activeNegativeModifiers: activeNegatives,
                }
            }
        default:
            return state;
    }
}

export default reducer;