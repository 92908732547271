import React from 'react';
import { Active, Default, ActiveIcon, DefaultIcon } from './FilterChip';
import { useSelector, useDispatch } from 'react-redux';
import { setClassStatus, filterWeapons } from '../../redux';

const FilterChipClass = ({name}) => {
    const classes = useSelector(state => state.classes.data);   
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setClassStatus(name));
        dispatch(filterWeapons());
    }

    if(classes[name]) {
        return <Active icon={<ActiveIcon />} label={name} onClick={() => handleClick()} />
    } else {
        return <Default icon={<DefaultIcon />} label={name} onClick={() => handleClick()} />
    }
}

export default FilterChipClass;
