import React from 'react';
import Filter from './filters/Filter';
import Weapons from './weapons/Weapons';
import { Box } from '@mui/material';
import Footer from './Footer';
import ChartMenu from './chart/ChartMenu';   
import ChartTrigger from './chart/ChartTrigger';

const App = () => {

    return (
        <Box sx={{px: {xs: 1, md: 2}, mx: 'auto', maxWidth: {xs: '100%', lg: '95%', xl: 1350}}}>
            <ChartMenu />
            <Filter />
            <Weapons />
            <ChartTrigger />
            <Footer />
        </Box>            
    );
}

export default App;
