import { FETCH_WEAPONS_REQUEST, SET_SORTING_TYPE, SET_SORTING_VALUE, FETCH_WEAPONS_SUCCESS, FETCH_WEAPONS_FAILURE } from './weaponsTypes';

const initialState = {
    loading: false,
    data: [],
    error: '', 
    sortValue: 'default',
    sortType: 'asce',
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_WEAPONS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_WEAPONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: ''
            }
        case FETCH_WEAPONS_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload
            }
        case SET_SORTING_TYPE:
            return {
                ...state,
                sortType: action.payload, 
            }
        case SET_SORTING_VALUE:
             return {
                ...state,
                sortValue: action.payload
             }
        default: 
            return state;
    }
}

export default reducer;