import { FETCH_ATTCHS_REQUEST, FETCH_ATTCHS_SUCCESS, FETCH_ATTCHS_FAILURE } from './attchsTypes';
import axios from 'axios';
import api from '../../helpers/api';
import { formatName } from '../../helpers/general';

export const fetchAttchs = (weapon) => async dispatch => {
    const weaponName = formatName(weapon);
    dispatch(fetchAttchsRequest());
    
    try {
        const res = await axios.get(`${api}attachments/weapon/?weapon=${weapon}`);
        dispatch(fetchAttchsSuccess(weaponName, res.data));
    } catch(err) {
        dispatch(fetchAttchsFailure(err))
    }
}

export const fetchAttchsRequest = () => ({
    type: FETCH_ATTCHS_REQUEST,
});

export const fetchAttchsSuccess = (weapon, data) => ({
    type: FETCH_ATTCHS_SUCCESS,
    payload: {
        weapon: weapon, 
        data
    }
});

export const fetchAttchsFailure = err => ({
    type: FETCH_ATTCHS_FAILURE,
    payload: err
})