import React from 'react';
import { Drawer, styled, Typography, Box, Tooltip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import LineGraph from './LineGraph';
import CloseIcon from '@mui/icons-material/Close';
import { openChart } from '../../redux';

const Close = styled(CloseIcon)(({theme}) => ({
    background: theme.palette.error.main,
    padding: theme.spacing(0.5),
    margin: theme.spacing(1),
    borderRadius: 5,
    fontSize: 24,
    cursor: 'pointer'
}));
    
const ChartMenu = () => {
    const { open, active }  = useSelector(state => state.chart);
    const dispatch = useDispatch();

    if(active.length > 0) {
        return (
            <Drawer hideBackdrop variant="persistent" open={open} anchor="left" PaperProps={{sx: {height: {xs: 400, md: 'auto'}, background: 'background.default    ', maxHeight: 612, width: {xs: 'calc(100vw - 16px)', md: '50vw'}, border: 1, borderColor: 'common.black'}}}>
                <Box sx={{display: 'flex'}}>
                    <Tooltip title="Close"><Close onClick={() => dispatch(openChart())} /></Tooltip>
                </Box>
                <Typography children="Damage Over Time" align="center" variant="h5" gutterBottom />
                <Box sx={{position: 'relative'}}>
                    <Typography children="Damage (per second)" sx={{transform: 'rotate(-90deg)', position: 'absolute', left: -50, top: '50%'}} /> 
                    <Box sx={{ml: 6}}>
                        <LineGraph />
                    </Box>
                </Box>
                {
                    (active.length > 0) ? <Typography children="Distance (metres)" align="center" gutterBottom /> : '' 
                }
            </Drawer>
        );
    } else {
        return (
            <Drawer hideBackdrop variant="persistent" open={open} anchor="left" PaperProps={{sx: {height: {xs: 400, md: 'auto'}, background: 'background.default    ', maxHeight: 612, width: {xs: 'calc(100vw - 16px)', md: '50vw'}, border: 1, borderColor: 'common.black'}}}>
                <Box sx={{display: 'flex'}}>
                    <Tooltip title="Close"><Close onClick={() => dispatch(openChart())} /></Tooltip>
                </Box>
                <Typography children="No weapons to compare at this time." variant="h6" align="center" sx={{mb: 4}} />
            </Drawer>
        );
    }
}

export default ChartMenu;
