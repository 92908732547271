import React from 'react';
import { SvgIcon } from '@mui/material';

const AdvantageIcon = () => {
    return (
        <SvgIcon sx={{position: 'relative', top: 2}}>
            <path d="M0.433757 5.99101C0.515343 6.0748 0.627434 6.12183 0.744382 6.12138H3.023C3.20075 6.12138 3.34485 6.26549 3.34485 6.44323V13.5687C3.34485 13.8069 3.53792 14 3.77611 14H8.21106C8.44924 14 8.64231 13.8069 8.64231 13.5687V6.44323C8.64231 6.26549 8.78642 6.12138 8.96416 6.12138H11.2492C11.4874 6.12588 11.6841 5.93649 11.6886 5.69836C11.6908 5.57915 11.6436 5.46432 11.5582 5.38116L6.30576 0.12875C6.22587 0.0475864 6.11712 0.00130177 6.00322 4.76837e-06C5.88709 -0.000417709 5.77572 0.0459871 5.69426 0.12875L0.441843 5.38116C0.271218 5.54732 0.267597 5.82035 0.433757 5.99101Z" fill="#00E676"/>
        </SvgIcon>
    );
}

export default AdvantageIcon;
