import { combineReducers } from "redux";
import classesReducer from './classes/classesReducer ';
import gamesReducer from './games/gamesReducer';
import weaponsReducer from './weapons/weaponsReducer';
import attchsReducer from './attchs/attchsReducer';
import modifiersReducer from './modifiers/modifiersReducer';
import chartReducer from './chart/chartReducer';

const rootReducer = combineReducers({
    classes: classesReducer,
    games: gamesReducer,
    weapons: weaponsReducer,
    attchs: attchsReducer,
    modifiers: modifiersReducer,
    chart: chartReducer,
});

export default rootReducer;