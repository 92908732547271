import api from './api';
import axios from 'axios';

export const sortAttchsByType = attchs => {
    const ammo = [], sights = [], barrel = [], underbarrel = [];
    
    attchs.forEach(attch => {
        switch(attch.type) {
            case 'Ammo':
                return ammo.push(attch);
            case 'Sights':
                return sights.push(attch);
            case 'Barrel':
                return barrel.push(attch);
            case 'Underbarrel':
                return underbarrel.push(attch);
        }
    });

    return {ammo, sights, barrel, underbarrel}
}

export const formatAttchDetails = str => {
    return str.replace(/[+-]/g,'').split(';');
}

export const calcCombinedModifiers = (activeAttchs, statName, baseValue) => {
    const reducedValue = [activeAttchs.ammo[statName], activeAttchs.sights[statName], activeAttchs.underbarrel[statName], activeAttchs.barrel[statName]].reduce((total, current) => {
        if(current === 0) return total;
        return Number(total) + Number(current);
    });

    return Number(baseValue) + Number(reducedValue);
}

export const getImgSrc = async (attchName, weaponName, type, id, setImgSrc) => {
    const {data} = await axios.get(`${api}images/attachments?attchName=${attchName}&weaponName=${weaponName}&type=${type.toLowerCase()}&id=${id}`);
    setImgSrc(data);
}